import { throttle } from 'throttle-debounce';

window.addEventListener('load', () => {
  const header = document.querySelector('.header');
  const spLinks = header.querySelectorAll('.hamburger__menu__nav a');
  const checkbox = header.querySelector('#hamburger-check');
  if (!header || !spLinks || !checkbox) return;

  // scroll up 時にヘッダーを固定
  let lastScrollY = window.scrollY;
  window.addEventListener('scroll', throttle(200, () => {
    const isSticky = header.classList.contains('is-sticky');

    if (window.scrollY > 100 && lastScrollY >= window.scrollY && !isSticky) {
      header.classList.add('is-sticky');
    } else if ((window.scrollY <= 100 || lastScrollY < window.scrollY) && isSticky) {
      header.classList.remove('is-sticky');
    }
    lastScrollY = window.scrollY;
  }));

  // SP メニュー内リンクから遷移時にメニューを閉じる
  spLinks.forEach((spLink) => {
    spLink.addEventListener('click', () => {
      checkbox.checked = false;
      return true;
    });
  });
});
